import React from "react"
import { Route, Redirect} from "react-router-dom"
import { useAuth } from "../contexts/AuthContext"

export default function PrivateRoute({component: Component, ...rest}) {
    const { currentUser , thisUser } = useAuth()

    return (
        <Route
            {...rest}
            render={ props => 
                ((currentUser && thisUser) ? <Component {...props} /> : <Redirect to="/" />)
            }
        />
    )
}